
$success:  #28a745 !important;
$info:  #17a2b8 !important;
$warning:  #ffc107 !important;
$danger:  #dc3545 !important;
#toast-container > div {
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);
  max-width: none;
}

// toast container hover shadow

// toastify colors

.Toastify__toast-container {
  & > div {
    opacity: 0.9;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
    filter: alpha(opacity=90);
    max-width: none;
    &:hover {
      -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
    }
  }

  .Toastify__toast--success {
    background: $success;
  }

  .Toastify__toast--info {
    background: $info;
  }

  .Toastify__toast--warning {
    background: $warning;
  }

  .Toastify__toast--error {
    background: $danger;
  }
}


// toast container opacity

#toast-container > div {
    opacity: 0.9;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
    filter: alpha(opacity=90);
    max-width: none;
  }
  
  // toast container hover shadow
  
  // toastify colors
  
  .Toastify__toast-container {

    .Toastify__toast-body{
        svg{
            stroke: #fff;
        }
        color: white;
    }
    & > div {
      opacity: 0.9;
      -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
      filter: alpha(opacity=90);
      max-width: none;
      &:hover {
        -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.75);
      }
    }
  
    .Toastify__toast--success {
      background: $success ;
    }
  
    .Toastify__toast--info {
      background: $info;
    }
  
    .Toastify__toast--warning {
      background: $warning;
    }
  
    .Toastify__toast--error {
      background: $danger;
    }
  }
  